
    <template>
      <section class="content element-doc">
        <h2>国际化</h2>
<p>Element 组件内部默认使用中文，若希望使用其他语言，则需要进行多语言设置。以英文为例，在 main.js 中：</p>
<pre><code class="hljs language-javascript"><span class="hljs-comment">// 完整引入 Element</span>
<span class="hljs-keyword">import</span> Vue <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue&#x27;</span>
<span class="hljs-keyword">import</span> ElementUI <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui&#x27;</span>
<span class="hljs-keyword">import</span> locale <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui/lib/locale/lang/en&#x27;</span>

Vue.use(ElementUI, { locale })
</code></pre>
<p>或</p>
<pre><code class="hljs language-javascript"><span class="hljs-comment">// 按需引入 Element</span>
<span class="hljs-keyword">import</span> Vue <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue&#x27;</span>
<span class="hljs-keyword">import</span> { Button, Select } <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui&#x27;</span>
<span class="hljs-keyword">import</span> lang <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui/lib/locale/lang/en&#x27;</span>
<span class="hljs-keyword">import</span> locale <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui/lib/locale&#x27;</span>

<span class="hljs-comment">// 设置语言</span>
locale.use(lang)

<span class="hljs-comment">// 引入组件</span>
Vue.component(Button.name, Button)
Vue.component(Select.name, Select)
</code></pre>
<p>如果使用其它语言，默认情况下中文语言包依旧是被引入的，可以使用 webpack 的 NormalModuleReplacementPlugin 替换默认语言包。</p>
<p><strong>webpack.config.js</strong></p>
<pre><code class="hljs language-javascript">{
  <span class="hljs-attr">plugins</span>: [
    <span class="hljs-keyword">new</span> webpack.NormalModuleReplacementPlugin(
      <span class="hljs-regexp">/element-ui[\/\\]lib[\/\\]locale[\/\\]lang[\/\\]zh-CN/</span>,
      <span class="hljs-string">&#x27;element-ui/lib/locale/lang/en&#x27;</span>
    )
  ]
}
</code></pre>
<h2>兼容 <code>vue-i18n@5.x</code></h2>
<p>Element 兼容 <a href="https://github.com/kazupon/vue-i18n">vue-i18n@5.x</a>，搭配使用能更方便地实现多语言切换。</p>
<pre><code class="hljs language-javascript"><span class="hljs-keyword">import</span> Vue <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue&#x27;</span>
<span class="hljs-keyword">import</span> VueI18n <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue-i18n&#x27;</span>
<span class="hljs-keyword">import</span> Element <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui&#x27;</span>
<span class="hljs-keyword">import</span> enLocale <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui/lib/locale/lang/en&#x27;</span>
<span class="hljs-keyword">import</span> zhLocale <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui/lib/locale/lang/zh-CN&#x27;</span>

Vue.use(VueI18n)
Vue.use(Element)

Vue.config.lang = <span class="hljs-string">&#x27;zh-cn&#x27;</span>
Vue.locale(<span class="hljs-string">&#x27;zh-cn&#x27;</span>, zhLocale)
Vue.locale(<span class="hljs-string">&#x27;en&#x27;</span>, enLocale)
</code></pre>
<h2>兼容其他 i18n 插件</h2>
<p>如果不使用 <code>vue-i18n@5.x</code>，而是用其他的 i18n 插件，Element 将无法兼容，但是可以自定义 Element 的 i18n 的处理方法。</p>
<pre><code class="hljs language-javascript"><span class="hljs-keyword">import</span> Vue <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue&#x27;</span>
<span class="hljs-keyword">import</span> Element <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui&#x27;</span>
<span class="hljs-keyword">import</span> enLocale <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui/lib/locale/lang/en&#x27;</span>
<span class="hljs-keyword">import</span> zhLocale <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui/lib/locale/lang/zh-CN&#x27;</span>

Vue.use(Element, {
  <span class="hljs-attr">i18n</span>: <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params">path, options</span>) </span>{
    <span class="hljs-comment">// ...</span>
  }
})
</code></pre>
<h2>兼容 <code>vue-i18n@6.x</code></h2>
<p>默认不支持 6.x 的 <code>vue-i18n</code>，你需要手动处理。</p>
<pre><code class="hljs language-javascript"><span class="hljs-keyword">import</span> Vue <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue&#x27;</span>
<span class="hljs-keyword">import</span> Element <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui&#x27;</span>
<span class="hljs-keyword">import</span> VueI18n <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue-i18n&#x27;</span>
<span class="hljs-keyword">import</span> enLocale <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui/lib/locale/lang/en&#x27;</span>
<span class="hljs-keyword">import</span> zhLocale <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui/lib/locale/lang/zh-CN&#x27;</span>

Vue.use(VueI18n)

<span class="hljs-keyword">const</span> messages = {
  <span class="hljs-attr">en</span>: {
    <span class="hljs-attr">message</span>: <span class="hljs-string">&#x27;hello&#x27;</span>,
    ...enLocale <span class="hljs-comment">// 或者用 Object.assign({ message: &#x27;hello&#x27; }, enLocale)</span>
  },
  <span class="hljs-attr">zh</span>: {
    <span class="hljs-attr">message</span>: <span class="hljs-string">&#x27;你好&#x27;</span>,
    ...zhLocale <span class="hljs-comment">// 或者用 Object.assign({ message: &#x27;你好&#x27; }, zhLocale)</span>
  }
}
<span class="hljs-comment">// Create VueI18n instance with options</span>
<span class="hljs-keyword">const</span> i18n = <span class="hljs-keyword">new</span> VueI18n({
  <span class="hljs-attr">locale</span>: <span class="hljs-string">&#x27;en&#x27;</span>, <span class="hljs-comment">// set locale</span>
  messages <span class="hljs-comment">// set locale messages</span>
})

Vue.use(Element, {
  <span class="hljs-attr">i18n</span>: <span class="hljs-function">(<span class="hljs-params">key, value</span>) =&gt;</span> i18n.t(key, value)
})

<span class="hljs-keyword">new</span> Vue({ i18n }).$mount(<span class="hljs-string">&#x27;#app&#x27;</span>)
</code></pre>
<h2>按需加载里定制 i18n</h2>
<pre><code class="hljs language-js"><span class="hljs-keyword">import</span> Vue <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue&#x27;</span>
<span class="hljs-keyword">import</span> DatePicker <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element/lib/date-picker&#x27;</span>
<span class="hljs-keyword">import</span> VueI18n <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue-i18n&#x27;</span>

<span class="hljs-keyword">import</span> enLocale <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui/lib/locale/lang/en&#x27;</span>
<span class="hljs-keyword">import</span> zhLocale <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui/lib/locale/lang/zh-CN&#x27;</span>
<span class="hljs-keyword">import</span> ElementLocale <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element-ui/lib/locale&#x27;</span>

Vue.use(VueI18n)
Vue.use(DatePicker)

<span class="hljs-keyword">const</span> messages = {
  <span class="hljs-attr">en</span>: {
    <span class="hljs-attr">message</span>: <span class="hljs-string">&#x27;hello&#x27;</span>,
    ...enLocale
  },
  <span class="hljs-attr">zh</span>: {
    <span class="hljs-attr">message</span>: <span class="hljs-string">&#x27;你好&#x27;</span>,
    ...zhLocale
  }
}
<span class="hljs-comment">// Create VueI18n instance with options</span>
<span class="hljs-keyword">const</span> i18n = <span class="hljs-keyword">new</span> VueI18n({
  <span class="hljs-attr">locale</span>: <span class="hljs-string">&#x27;en&#x27;</span>, <span class="hljs-comment">// set locale</span>
  messages <span class="hljs-comment">// set locale messages</span>
})

ElementLocale.i18n(<span class="hljs-function">(<span class="hljs-params">key, value</span>) =&gt;</span> i18n.t(key, value))
</code></pre>
<h2>通过 CDN 的方式加载语言文件</h2>
<pre><code class="hljs language-html"><span class="hljs-tag">&lt;<span class="hljs-name">script</span> <span class="hljs-attr">src</span>=<span class="hljs-string">&quot;//unpkg.com/vue&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span> <span class="hljs-attr">src</span>=<span class="hljs-string">&quot;//unpkg.com/element-ui&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span> <span class="hljs-attr">src</span>=<span class="hljs-string">&quot;//unpkg.com/element-ui/lib/umd/locale/en.js&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span>
  ELEMENT.locale(ELEMENT.lang.en)
<span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
<p>搭配 <code>vue-i18n</code> 使用</p>
<pre><code class="hljs language-html"><span class="hljs-tag">&lt;<span class="hljs-name">script</span> <span class="hljs-attr">src</span>=<span class="hljs-string">&quot;//unpkg.com/vue&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span> <span class="hljs-attr">src</span>=<span class="hljs-string">&quot;//unpkg.com/vue-i18n/dist/vue-i18n.js&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span> <span class="hljs-attr">src</span>=<span class="hljs-string">&quot;//unpkg.com/element-ui&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span> <span class="hljs-attr">src</span>=<span class="hljs-string">&quot;//unpkg.com/element-ui/lib/umd/locale/zh-CN.js&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span> <span class="hljs-attr">src</span>=<span class="hljs-string">&quot;//unpkg.com/element-ui/lib/umd/locale/en.js&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  Vue.locale(<span class="hljs-string">&#x27;en&#x27;</span>, ELEMENT.lang.en)
  Vue.locale(<span class="hljs-string">&#x27;zh-cn&#x27;</span>, ELEMENT.lang.zhCN)
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
<p>目前 Element 内置了以下语言：</p>
<ul class="language-list">
  <li>简体中文（zh-CN）</li>
  <li>英语（en）</li>
  <li>德语（de）</li>
  <li>葡萄牙语（pt）</li>
  <li>西班牙语（es）</li>
  <li>丹麦语（da）</li>
  <li>法语（fr）</li>
  <li>挪威语（nb-NO）</li>
  <li>繁体中文（zh-TW）</li>
  <li>意大利语（it）</li>
  <li>韩语（ko）</li>
  <li>日语（ja）</li>
  <li>荷兰语（nl）</li>
  <li>越南语（vi）</li>
  <li>俄语（ru-RU）</li>
  <li>土耳其语（tr-TR）</li>
  <li>巴西葡萄牙语（pt-br）</li>
  <li>波斯语（fa）</li>
  <li>泰语（th）</li>
  <li>印尼语（id）</li>
  <li>保加利亚语（bg）</li>
  <li>波兰语（pl）</li>
  <li>芬兰语（fi）</li>
  <li>瑞典语（sv-SE）</li>
  <li>希腊语（el）</li>
  <li>斯洛伐克语（sk）</li>
  <li>加泰罗尼亚语（ca）</li>
  <li>捷克语（cs-CZ）</li>
  <li>乌克兰语（ua）</li>
  <li>土库曼语（tk）</li>
  <li>泰米尔语（ta）</li>
  <li>拉脱维亚语（lv）</li>
  <li>南非荷兰语（af-ZA）</li>
  <li>爱沙尼亚语（ee）</li>
  <li>斯洛文尼亚语（sl）</li>
  <li>阿拉伯语（ar）</li>
  <li>希伯来语（he）</li>
  <li>立陶宛语（lt）</li>
  <li>蒙古语（mn）</li>
  <li>哈萨克斯坦语（kz）</li>
  <li>匈牙利语（hu）</li>
  <li>罗马尼亚语（ro）</li>
  <li>库尔德语（ku）</li>
  <li>维吾尔语（ug-CN）</li>
  <li>高棉语（km）</li>
  <li>塞尔维亚语（sr）</li>
  <li>巴斯克语（eu）</li>
  <li>吉尔吉斯语（kg）</li>
  <li>亚美尼亚语 (hy)</li>
  <li>克罗地亚 (hr)</li>
  <li>世界语 (eo)</li>
</ul>
<p>如果你需要使用其他的语言，欢迎贡献 PR：只需在 <a href="https://github.com/ElemeFE/element/tree/dev/src/locale/lang">这里</a> 添加一个语言配置文件即可。</p>

      </section>
    </template>
    
  